import React, { useState, useEffect, useCallback } from 'react';

const ENERGY_LOSS = 0.7;
const GAME_OVER_LINE = 50;
const TILT_DURATION = 100;

const COLORS = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F06292', '#BA68C8'];

const Ball = ({ ball }) => {
  return (
    <div
      key={ball.id}
      style={{
        position: 'absolute',
        left: ball.x - ball.size / 2,
        top: ball.y - ball.size / 2,
        width: ball.size,
        height: ball.size,
        borderRadius: '50%',
        backgroundColor: COLORS[ball.level - 1],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        fontWeight: 'bold'
      }}
    >
      {ball.level}
    </div>
  );
}

const BallFusionGame = () => {
  const [balls, setBalls] = useState([]);
  const [isGameOver, setIsGameOver] = useState(false);
  const [tiltX, setTiltX] = useState(0);
  const [tiltY, setTiltY] = useState(0);
  const [nextLevel, setNextLevel] = useState(1);

  const createBall = useCallback((x, y, level) => ({
    id: Date.now() + Math.random(),
    x,
    y,
    vx: 0,
    vy: 0,
    level,
    size: 40 + level * 20,
    exemptGameOver: true
  }), []);

  useEffect(() => {
    const gameLoop = setInterval(() => {
      setBalls(prevBalls => {
        const newBalls = prevBalls.map(ball => {
          let newX = ball.x + ball.vx;
          let newY = ball.y + ball.vy + 0.5; // Gravity
          
          let contactWithWall = false;
          // Boundary check
          if (newX - ball.size / 2 < 0 || newX + ball.size / 2 > 400) {
            ball.vx *= -ENERGY_LOSS; // Bounce off walls with some energy loss
            newX = Math.max(ball.size / 2, Math.min(400 - ball.size / 2, newX));
            contactWithWall = true;
          }
          if (newY + ball.size / 2 > 600) {
            ball.vy *= -ENERGY_LOSS; // Bounce off floor with some energy loss
            newY = 600 - ball.size / 2;
            ball.exemptGameOver = false;
            contactWithWall = true;
          }

          // apply tilt effect if in contact with wall
          if (contactWithWall) {
            // Apply tilt effect
            ball.vx += tiltX * 0.05;
            ball.vy += tiltY * 0.1;
          }

          return { ...ball, x: newX, y: newY, vy: ball.vy + 0.2, vx: ball.vx * 0.99 }; // Apply gravity and friction
        });

        // Collision detection and resolution
        for (let i = 0; i < newBalls.length; i++) {
          for (let j = i + 1; j < newBalls.length; j++) {
            const ball1 = newBalls[i];
            const ball2 = newBalls[j];
            const dx = ball2.x - ball1.x;
            const dy = ball2.y - ball1.y;
            const distance = Math.sqrt(dx * dx + dy * dy);
            const minDistance = (ball1.size + ball2.size) / 2;

            if (distance < minDistance) {
              // Collision detected
              const angle = Math.atan2(dy, dx);
              const sin = Math.sin(angle);
              const cos = Math.cos(angle);

              // Rotate velocities
              const vx1 = ball1.vx * cos + ball1.vy * sin;
              const vy1 = ball1.vy * cos - ball1.vx * sin;
              const vx2 = ball2.vx * cos + ball2.vy * sin;
              const vy2 = ball2.vy * cos - ball2.vx * sin;

              // Swap velocities for realistic bounce effect
              ball1.vx = vx2 * cos - vy1 * sin;
              ball1.vy = vy1 * cos + vx2 * sin;
              ball2.vx = vx1 * cos - vy2 * sin;
              ball2.vy = vy2 * cos + vx1 * sin;

              // Move balls apart
              const overlap = minDistance - distance;
              ball1.x -= overlap * cos / 2;
              ball1.y -= overlap * sin / 2;
              ball2.x += overlap * cos / 2;
              ball2.y += overlap * sin / 2;

              // Now they might cause game over
              if (ball1.y > GAME_OVER_LINE && ball2.y > GAME_OVER_LINE) {
                ball1.exemptGameOver = false;
                ball2.exemptGameOver = false;  
              }

              // Check for fusion
              if (ball1.level === ball2.level) {
                const newLevel = ball1.level + 1;
                const newBall = createBall((ball1.x + ball2.x) / 2, (ball1.y + ball2.y) / 2, newLevel);
                newBalls.splice(j, 1);
                newBalls.splice(i, 1);
                newBalls.push(newBall);
                return newBalls;
              }
            }
          }
        }

        // if any ball reaches the top, game over
        if (!isGameOver && newBalls.some(ball => !ball.exemptGameOver && ball.y - ball.size / 2 < GAME_OVER_LINE)) {
          setIsGameOver(true);
        }

        return newBalls;
      });
    }, 16); // Approximately 60 FPS

    return () => clearInterval(gameLoop);
  }, [createBall, tiltX, tiltY]);

  const handleGameAreaClick = (e) => {
    if (isGameOver) return;

    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = 50; // Start from top
    const level = nextLevel;
    const newBall = createBall(x, y, level);

    setNextLevel(Math.floor(Math.random() * 4) + 1);

    setBalls(prevBalls => [...prevBalls, newBall]);
  };

  const handleOutsideClick = (e) => {
    e.preventDefault();
    if (isGameOver) return;

    const gameArea = document.getElementById('game-area');
    const rect = gameArea.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;

    // if it's inside the game area, don't tilt
    if (e.clientX > rect.left && e.clientX < rect.right && e.clientY > rect.top && e.clientY < rect.bottom) {
      return;
    }
    
    // Apply tilt based on click position relative to game area
    if (e.clientY > rect.bottom) {
      const newTilt = (e.clientY - rect.bottom) / rect.height * 100;
      setTiltY(-newTilt);
    } else {
      const newTilt = (e.clientX - centerX) / rect.width * 5;
      setTiltX(newTilt);
    }

    // Reset tilt after a short delay
    setTimeout(() => setTiltX(0), TILT_DURATION);
    setTimeout(() => setTiltY(0), TILT_DURATION);
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div style={{ position: 'relative', width: '400px', margin: '0 auto' }}>
      <div 
        id="game-area"
        style={{ 
          position: 'relative', 
          width: '400px', 
          height: '600px', 
          border: '1px solid black',
          transform: `rotate(${tiltX}deg) translateY(${tiltY}px)`,
          transition: 'transform 0.1s ease-out',
          overflow: 'hidden'
        }} 
        onClick={handleGameAreaClick}
      >
        {balls.map(ball => <Ball ball={ball} />)}
        <div style={{ position: 'absolute', top: '10px', left: '300px', fontSize: '20px' }}>
          NEXT：{nextLevel}
        </div>
        <div style={{ position: 'absolute', top: `${GAME_OVER_LINE}px`, left: '0', width: '400px', height: '2px', backgroundColor: 'red'}}>
        </div>
        {isGameOver && (
          <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ color: 'white', fontSize: '30px', fontWeight: 'bold' }}>ゲームオーバー</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BallFusionGame;